<script setup>

</script>
<template>
    <div class="bg-cool-gray-light mb-[96px] lg:mb-[112px] pt-16 text-center lg:text-left">
        <Container no-paddings>
            <div class="grid grid-cols-1 lg:grid-cols-2">
                <div>
                    <p class="text-azure-dark text-4xl">
                        Porozmawiajmy
                    </p>
                    <p class="text-4xl md:text-[64px] md:leading-[64px] font-semibold text-azure my-6 my-14 lg:my-8">
                        <a href="tel:+48602414305">
                            <span class="text-2xl md:text-4xl font-normal">
                                +48
                            </span>
                            602 414 305
                        </a>
                    </p>
                    <div>
                        <p class="mb-4 lg:mb-2 text-azure-dark text-xl">
                            Tomasz Patrzykowski
                        </p>
                        <p class="mb-4 lg:mb-2 font-light">
                            Dyrektor ds. Sprzedaży
                        </p>
                        <p class="text-azure">
                            <IconMail class="inline-block mr-2 " />
                            <a href="mailto:tpatrzykowski@vehis.pl">tpatrzykowski@vehis.pl</a>
                        </p>
                    </div>
                </div>
                <div class="w-full flex justify-center xl:justify-end items-end">
                    <img class="w-full xl:w-auto h-[300px] sm:object-contain mt-16 lg:mt-0" src="@/assets/images/statics/kongres-phone.png" alt="" />
                </div>
            </div>
        </Container>
    </div>
</template>