<script setup>
import carSalesFlow from '@/assets/images/portals/car-sales-flow.svg'

useHead({
  title: "Twój biznes ONline",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: `https://vehis.pl${useRoute().fullPath}`}
  ]
});

const backend = useBackend();

const slides = useSlides();

const refControl = ref();
const refSlideNavigation = ref();

onMounted(() => slides.initSlides(refControl, refSlideNavigation))
const faqData =  [
  {
    question: "Na czym polega wdrożenie narzędzia w moim dealerstwie?",
    answer:
      "Nasze narzędzie to lekka aplikacja online, która nie wymaga budowania skomplikowanej infrastruktury informatycznej. Całym procesem wdrożenia zajmą się specjaliści z Webwizards.",
  },
  {
    question: "Jakie funkcjonalności oferuje narzędzie VEHIS i Webwizards?",
    answer:
      "Nasze rozwiązanie obejmuje między innymi zarządzanie stockiem, eksport na stock VEHIS wraz z dokumentami i zdjęciami samochodów, eksport na inne portale, rezerwacje pojazdów i wiele innych funkcjonalności. Chętnie przedstawimy wszystkie podczas rozmowy.",
  },
  {
    question: "Jakie koszty muszę ponieść?",
    answer:
      "W skrócie, żadne. Narzędzie Car Sales Flow moduł VEHIS oddajemy w Twoje ręce bezpłatnie!",
  },
  {
    question: "Czy oferowane narzędzie ma możliwość integracji z moimi systemami?",
    answer:
      "Tak, możliwa jest integracja z dealerskim DMS oraz narzędziami automatyzacji.",
  },
  {
    question: "Co zyskam na współpracy z VEHIS?",
    answer:
      "VEHIS bez kosztów z Twojej strony zapewnia kompleksowe działania reklamowe oraz obsługę całego procesu sprzedaży, od pozyskania leada do zawarcia umowy.",
  },
  {
    question: "Czy zostanę przeszkolony z obsługi narzędzia i uzyskam wsparcie techniczne?",
    answer:
      "Oczywiście, otrzymasz pakiet szkoleń i materiałów instruktażowych. Dodatkowo będziesz mieć dostęp do suportu wspierającego codzienną pracę.",
  }
]

const formOpened = ref(false);
const breakpoints = useBreakpoints();
const isMobileView = computed(() => breakpoints.lte('lg'));
const openForm = (event) => {
    if(isMobileView.value) {
      event.preventDefault();
      formOpened.value = true;
    } 
}
</script>

<template>
  <div class="sticky top-[93px] md:top-[108px] z-50">
    <Container no-paddings gray>
      <MenuScrolled>
        <AnchorJump>Twój biznes ONline</AnchorJump>
        <AnchorJump to="details">Poznaj szczegóły</AnchorJump>
        <AnchorJump to="video">Wideo</AnchorJump>
        <AnchorJump to="contact">Porozmawiajmy</AnchorJump>
        <AnchorJump to="about">O VEHIS</AnchorJump>
        <AnchorJump to="faq">FAQ</AnchorJump>
      </MenuScrolled>
    </Container>
  </div>
  <Container>
    <StaticMainHeader
      isCongressPage
      :heroBoxesData="[
            {
                heading: 'DMS bez kosztów',
                txt: 'udostępniamy go za darmo!'
            },
            {
                heading: 'Natychmiastowy start',
                txt: 'bez skomplikowanego wdrożenia.'
            },
            {
                heading: 'Twój stock na vehis.pl',
                txt: 'bezpłatnie, z aktualizacją online.'
            }
        ]"
      >
      <template v-slot:headerDescription>
          <div>
            <img class="w-full max-w-[400px] mt-2 mb-12" src="@/assets/images/statics/kongres-online.png" alt="" />
              <div>
                  <p class="text-2xl text-coolGray-700 mb-3">
                      Gdy branża się zmienia, to nie czas by pozostać w trybie “stand by”.
                  </p>
                  <p class="text-2xl text-coolGray-700">
                    Wspólnie z <img class="max-h-[70px] inline align-bottom pb-[5.5px] px-2" src="@/assets/images/statics/kongres-webwizard.png" alt="" />
                    stworzyliśmy dla Ciebie oprogramowanie DMS w wersji VEHIS,
                    z którym będziesz nie tylko <span class="text-azure">ON</span>, ale też <span class="text-azure">ONline</span>.
                  </p>
              </div>
          </div>
      </template>
      <template v-slot:mainImage>
        <img class="mt-12 lg:mt-0 mb-4 m-auto max-h-[400px] w-auto" src="@/assets/images/statics/kongres-switch.png" alt="" />
      </template>
    </StaticMainHeader>
  </Container>

  <section id="details">
    <div class="bg-cool-gray-light">
      <Container>
        <div class="flex justify-center items-center">
          <img :src="carSalesFlow" alt="Car Sales Flow" class="w-32 h-32" />
          <div class="flex items-center"><span class="mx-4 font-light text-xl">x</span><span class="text-4xl font-medium tracking-widest">VEHIS</span></div>
        </div>
        <h2 class="text-3xl text-azure-dark text-center">Nowa odsłona sprawdzonego narzędzia</h2>
        <p class="mt-8 text-center text-xl text-cool-gray-700 mx-auto xl:px-14">
          Potrzebujesz rozwiązania, które nie tylko pozwoli Ci zintegrować działania dealerskie w jednym miejscu, ale też automatycznie udostępniać swoje oferty samochodów do sprzedaży online?
        </p>
        <div class="mt-10 font-thin text-xl lg:flex lg:gap-4">
          <div class="mb-10 lg:flex-1">
            <p>Car Sales Flow to oprogramowanie DMS do:</p>
            <ul class="ml-10 list-disc">
              <li>zarządzania stockiem</li>
              <li>eksportu ofert na portale sprzedażowe</li>
              <li>prowadzenia procesu sprzedaży samochodu</li>
              <li>przygotowania i wydania samochodu.</li>
            </ul>
          </div>
          <div class="lg:flex-1">
            <p class="mb-10">Dodatkowo wzbogaciliśmy je o pełną integrację z VEHIS, czyli bezkosztową możliwość prezentacji i sprzedaży wszystkich Twoich samochodów w całym naszym ekosystemie.</p>
            <p class="mb-10">To najszybszy i najprostszy sposób wejścia w sprzedaż online.</p>
          </div>
        </div>
        <div class="flex justify-center mt-6 mb-8">
          <AnchorJump to="contact" class="bg-azure hover:bg-azure-dark rounded w-70 py-2 px-6 text-white font-light text-center">Skontaktuj się z nami</AnchorJump>
        </div>
      </Container>
    </div>

    <Container>
      <BlockHowTo
        noSelected
        :negMargin="false"
        textLeft 
        :textCenter="false"  
        :title="'Jak wprowadzić biznes w tryb ONline'"
        hideDescription
        :steps="[
            'Spotkaj się z nami i poznaj wszystkie zalety DMS w wersji VEHIS.',
            'Podpisz umowę o współpracy stockowej z VEHIS, z bonusami dla Ciebie.',
            'Z pomocą specjalistów z Webwizards uruchom narzędzie i korzystaj z licznych funkcjonalności.',
            'Twoje samochody zostaną automatycznie i bezpłatnie opublikowane w ekosystemie VEHIS.'
            ]"
        :customHeadingClass="'text-3xl text-azure-dark text-center'"
        />
    </Container>
  </section>

  <section id="video">
    <Container>
      <div class="w-full aspect-video mb-8">
        <iframe
          src="https://player.vimeo.com/video/1066541588?autoplay=0&muted=0"
          class="w-full h-full"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>
    </Container>
  </section>

  <section id="contact">
    <StaticCtaPhoneDirect/>
  </section>
  <section id="about">
    <Container noPaddings>
      <StaticAboutVehis />
    </Container>
  </section>
  <section id="faq">
    <StaticFaq :faqData="faqData" isCongressPage/>
  </section>
  <div class="bg-cool-gray-light">
    <Container>
      <SeoBrandsCloud noOuterPaddings />
    </Container>
  </div>
</template>

<style>
html {
  scroll-behavior: smooth;

}

#contactSection {
  scroll-margin-top: 150px;
}
</style>